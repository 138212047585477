.paymentprofiledetailspage {
  font-family: 'Poppins', sans-serif;
  background: #fff;
}
.paymentprofiledetailspage-card {
  padding: 20px 50px 60px 50px;
  margin: 30px 0 100px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.paymentprofiledetailspage-card > h1 {
  padding-top: 20px;
  font-size: 16px;
  text-align: center;
  color: rgba(19, 21, 22, 0.9);
  line-height: 28px;
  font-weight: 700;
}
.paymentprofiledetailspage-card > span {
  display: block;
  padding-bottom: 30px;
  color: rgba(19, 21, 22, 0.8);
  text-align: center;
}
.paymentprofiledetailspage-card > .table-responsive > .table {
  border: 1px solid rgba(39, 182, 186, 0.6);
  border-radius: 16px;
}
.btn-action {
  color: #cc5500;
}
@media (min-width: 768px) {
  .paymentprofiledetailspage-card > h1 {
    font-size: 20px;
  }
}