.professionalbanner {
width: 100%;
height: 250px;
position: relative;
background: rgba(19, 21, 22, 0.8);
font-family: 'Poppins', sans-serif;
}
.professionalbanner > img {
width: 100%;
height: 250px;
object-fit: fill;
}
.professionalbanner-desc {
  flex-direction: column;
}
.professionalbanner-details {
  padding-top: 100px;
  width:100%;
}
.professionalbanner-details > h1 {
  color: #fff;
  font-size: 40px;
}
.professionalbanner-details > h3 {
  color: #fff;
  font-size: 18px;
}
.professionalbanner-ratings {
  display: flex;
  align-items: center;
}
.professionalbanner-ratings > span {
  font-size: 14px;
  color: #fff;
}
.professionalbanner-contact {
  display: flex;
  width: 100%;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
  .professionalbanner-desc {
    flex-direction: row;
  }
  .professionalbanner-details {
    padding-top: 200px;
    width:50%;
  }
  .professionalbanner-contact {
    display: flex;
    width: 50%;
    padding-top: 310px;
    justify-content: space-between;
    align-items: center;
  }
}