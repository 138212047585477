.advertbanner {
  width: 100%;
  height: 450px;
  position: relative;
  background: rgba(19, 21, 22, 0.8);
  font-family: 'Poppins', sans-serif;
}
.advertbanner > img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.advertbanner-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(19, 21, 22, 0.5);
}