.productpage {
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
}
.productpage-image {
  padding: 70px 0 20px 0;
}
.propertyimage {
  position: relative;
}
.propertyimage > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.productpage-details {
  padding: 30px 0 20px 0;
}
.productpage-details > h1 {
  font-size: 16px;
  color: rgba(19, 21, 22, 0.8);
  padding: 25px 0 10px 0;
  font-weight: 600;
}
.productpage-details > h2 {
  font-size: 16px;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 700;
}
.productpage-details p:nth-child(2) {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.7);
}
.productpage-details > span {
  display: block;
  color: #cc5500;
  padding: 5px 0 20px 0;
}
.keyfeatures > h3 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(19, 21, 22, 0.4);
}
.keyfeatures > span {
  display: block;
  padding: 10px 0;
  font-size: 14px;
  color: rgba(19, 21, 22, 0.75);
  font-weight: 500;
  border-bottom: 1px solid rgba(19, 21, 22, 0.4);
}
.btn-flex {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.btn-flex > .btn {
  display: block;
  width: 100%;
  padding: 10px 10px;
}
.btn-flex > .btn:nth-child(1) {
  margin-right: 10px;
}
.padded-outline {
  padding: 10px 10px;
}
.productpage-tabs {
  display: flex;
  justify-content: center;
}
.productpage-tabs_content > span {
  display: block;
  font-size: 12px;
  color: rgba(19, 21, 22, 0.9);
}
.productpage-tabs_content > span:nth-child(2) {
  font-weight: 600;
  padding-bottom: 10px;
}
.rec-item-wrapper > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  z-index: 4;
}
.rec-arrow {
  position: absolute;
  border-radius: 0 !important;
  color: #fff !important;
  background-color: #000 !important;
  box-shadow: none !important;
  z-index: 5;
}
.rec-pagination {
  display: none !important;
}
.rec-arrow-right {
  right: 0;
}
.rec-slider-container {
  margin: 0 !important;
}
.productpage-tab_btn {
  margin-top: 12px;
  padding: 0px 15px;
  font-size: 14px;
  background: none;
  border: none;
}
.productpage-tab_btn:focus {
  outline: none;
}
.tabbtn-divider {
  border-right: 1px solid rgba(19, 21, 22, 0.4);
}
.productpage-icon {
  margin-bottom: 4px;
  margin-right: 5px;
}
.property-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 20px;
  background-color: rgba(241, 240, 240, 1);
}
.property-features > span {
  display: block;
  padding: 5px 0;
  font-size: 12px;
  color: rgba(19, 21, 22, 0.75);
  font-weight: 500;
  text-align: center;
}
.property-features > span:last-child {
  border-right: none;
}
.paymentplan {
  padding-top: 45px;
}
.paymentplan > h1 {
  font-size: 16px;
  padding-bottom: 5px;
}
.paymentplan-flex {
  display: flex;
  justify-content: flex-start;
}
.paymentplan-item {
  width: 100%;
}
.paymentplan-item {
  margin-bottom: 2px;
}
.paymentplan-item:first-child {
  margin-right: 2px;
}
.paymentplan-item > span {
  display: block;
  font-size: 14px;
  background-color: rgba(19, 21, 22, 1);
  color: #fff;
  padding: 14px 0;
  text-align: center;
}
.paymentplan-item > p {
  font-size: 14px;
  background-color: #fff;
  color: #000;
  padding: 18px 0;
  margin-bottom: 0 !important;
  font-weight: 600;
  text-align: center;
}
.paymentplan-total > span {
  display: block;
  font-size: 14px;
  padding: 14px 0;
  background-color: rgba(19, 21, 22, 1);
  color: #fff;
  text-align: center;
  font-weight: 500;
}
.paymentplan-total > p {
  font-size: 14px;
  padding: 18px 0;
  background-color: rgba(90, 91, 92, 1);
  color: #fff;
  text-align: center;
  font-weight: 600;
}
.productpage-cta {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}
.productpage-cta_item > p {
  text-align: center;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 500;
}
.productpage-cta_item > button {
  padding: 13px 50px;
  background-color: rgba(218, 37, 29, 1);
  border-radius: 0;
}
.similar-property {
  padding: 10px 0 100px 0;
}
.similar-property > h1 {
  font-size: 18px;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 0;
}
.similar-property > span {
  display: block;
  padding-bottom: 15px;
  color: rgba(26, 4, 4, 0.65);
  font-size: 14px;
}
@media (min-width: 768px) {
  .productpage-details {
    padding: 94px 0 20px 0;
  }
  .propertyimage > img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .rec-item-wrapper > img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    z-index: 4;
  }
  .property-features {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 15px 30px;
  }
  .property-features > span {
    display: block;
    padding: 0 30px 0 10px;
    font-size: 14px;
    border-right: 0.5px solid rgba(19, 21, 22, 0.4);
  }
}
