.how-card {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: transform 0.3s ease;
}

.how-card:hover {
  transform: translateY(-5px);
}

.how-card-step-number {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #ff7e39;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  line-height: 44px;
  text-align: left;
}
