.customer-feedback {
  padding: 60px 20px;
}

.feedback-header {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: #cc5500;
  margin-bottom: 40px;
}

.feedback-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}
