.sponsoredadvert {
  font-family: 'Poppins', sans-serif;
  padding: 50px 0;
}

.sponsoredadvert-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.paul-p, .ubosi-p, .ummahani-p, .jaiz-p {
  text-align: center;
}

.ummahani-p, .ubosi-p {
  padding-right: 17.7px;
}
.jaiz-p{
  padding-left: 17.7px;
}
.paul-p {
  margin-right: 15px;
}
.p-color {
  color: white;
}

.sponsoredadvert-subheader {
  display: block;
  padding: 0 0 20px 0;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.65);
;
  font-weight: 400;
}

.advert-per-div {
display: flex;
flex-direction: row;
}


.mobile-width {
  width: 16.031rem;
}

@media (min-width: 768px) {
  .sponsoredadvert {
    padding: 50px 0;
  }
}

@media screen and (max-width: 812px) {
  .advert-per-div {
    display: inherit;
    margin-top: 15px;
    -webkit-box-shadow:0px 2px 2px #cc5500;
 -moz-box-shadow:0px 2px 2px #cc5500;
 box-shadow:0px 2px 2px #cc5500;
  }
  .mobile-width {
    width: 19.344rem;
  }
  .jaiz-p{
    padding-right: 17.7px;
  }
  .paul-p, .ubosi-p, .ummahani-p, .jaiz-p {
    padding-top: 15px;
  }
}
