.approval-footer .logo {
  width: 100%;
  height: 100%;
  max-height: 200px;
  max-width: 200px;
  object-fit: contain;
}
.approval-footer .logo-container {
  margin-top: 50px;
}

@media screen and (max-width: 500px) {
  .approval-footer .logo {
    width: 100%;
    height: 100%;
    max-height: 130px;
    max-width: 130px;
    object-fit: contain;
  }
  .approval-footer {
    overflow-x: hidden;
  }
}
