.comment {
  display: flex;
  flex-direction: row;
}

.comment > img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 5px;
}
.ui .content {
  padding-left: 1.2rem;
}

.ui .content > .author {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 900;
}

.ui .content > .date {
  font-size: 11px;
}
