.buypage{
  font-family: 'Poppins', sans-serif;
  position: relative;
}
.buypage-header > h1 {
  padding: 30px 20px 0 0;
  font-size: 20px;
  color: rgba(19, 21, 22, 1);
  font-weight: 700;
}
.buypage-header > span {
  font-size: 14px;
}
.card-container {
  padding-top: 30px;
  position: relative;
}
@media (min-width: 768px) {
  .buypage-header > h1 {
    padding: 100px 50px 0 0;
    font-size: 20px;
    color: rgba(19, 21, 22, 1);
    font-weight: 700;
  }
  .aux-margin {
    margin-bottom: 30px;
  }
  .card-container {
    width: 100%;
    position: sticky;
    margin-right: 100%;
    top: 0;
    bottom: 0;
    padding: 10px 0 30px 0;
  }
}

@media (max-width: 768px) {
  .secondarybanner {
    min-height: 750px;
    position: relative;
    /* margin-top:80px; */
    /* display : none; */
  }
}

@media (max-width: 767px) {
  .secondarybanner {
    height: 350px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 736px) {
  .secondarybanner {
    height: 350px;

  }
}