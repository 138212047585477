.userdashboardpage .add-service-btn {
  background: #cc5500;
  border: 1px solid #cc5500;
  margin-top: 50px;
}

.homeservice-card {
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: rgba(243, 243, 243, 1);
}
.homeservice-card-content {
  padding-top: 20px;
}
.homeservice-card-content > h3 {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 500;
}
.homeservice-card-content > span {
  display: block;
  color: rgba(19, 21, 22, 0.6);
  padding-bottom: 3px;
  font-size: 13px;
  font-weight: 400;
}
.homeservice-card-content > .btn {
  color: #cc5500;
  font-size: 14px;
  padding-right: 0;
}
.homeservice-card > img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .homeservice-card {
    margin-top: 20px;
  }
}