.pre-approval-section {
  overflow-x: hidden;
  width: 100%;
  padding: 0px;
  gap: 0px !important;
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0px;
}
.pre-approval-section .image-container {
  height: 500px;
  position: relative;
}
.pre-approval-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pre-approval-section .title {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
  font-weight: 700;
  text-align: inherit;
  color: rgb(26, 24, 22);
  word-break: normal;
}
.pre-approval-section .description {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: inherit;
  color: rgb(26, 24, 22);
  word-break: normal;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
.pre-approval-section .approval-text {
  padding: 20px 50px;
}
.pre-approval-section .approval-text button {
  height: 48px;
  background: #cc5500;
  border-radius: 40px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  min-width: fit-content;
  width: max-content;
  padding: 12px 26px;
  position: relative;
}

@media (max-width: 768px) {
  .pre-approval-section .approval-text {
    padding: 30px 20px;
  }
}