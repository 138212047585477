.salescard {
  position: relative;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(19, 21, 22, 0.1);
  border-radius: 8px;
  cursor: pointer;
}


.navigation-disabled {
  cursor:unset;
}
.money {
  font-size: 22.5px;
  font-weight:100;
}
.bed-bath {
  font-size: 12px;
}

.salescard-image {
  width: 100%;
  height: 220px;
  position: relative;
}
.salescard-image > img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.salescard-header {
  width: 45%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 110px;
  padding-top:4px;
  /* padding-bottom:-4px; */
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  /* backdrop-filter: blur(1.5px); */
}
.salescard-header_text {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
  color: rgba(19, 21, 22, 0.7);
  font-weight: 500;
}
.salescard-header_price {
  margin-top: -15px;
  font-size: 14px;
  text-align: center;
  color: #cc5500;
  font-weight: 500;
}

.salescard-posted_time {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  width: 94px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cc5500;
  color: #fff;
  border-radius: 8px 0px 0px 0px;
}
.salescard-desc {
  padding: 20px 20px 30px 20px;
}
.salescard-desc_header {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.salescard-desc_text {
  color:rgba(19, 21, 22, 0.7);
  font-size: 12px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.salescard-desc_features {
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-size: 12px;
  color: rgba(19, 21, 22, 0.6);
  
}
.salescard-btn {
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
}
.salescard-btn > a {
  border: none;
  color:#cc5500;
}
.salescard-btn > a:hover {
  color: #e07325;
}
.salescard-reaction {
  display: flex;
  justify-content: center;
  color: rgba(19, 21, 22, 0.6);
  font-size: 12px;
  padding-bottom: 10px;
  color: #131111;
}
@media (min-width: 768px) {
  .salescard {
    margin-top: 20px;
  }
}