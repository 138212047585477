.navbar {
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 4px 8px rgba(19, 21, 22, 0.12);
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other elements */
  background-color: white; /* Make sure background color is set */
  transition: all 0.5s ease; /* Add a smooth transition for shadow effects */
  height: 150px;
}

.navbar.is-scrolled {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100px;
}

.navbar-light .navbar-nav .nav-link.active {
  border-bottom: 2px solid #cc5500;
}

.navbar-nav .nav-link {
  font-size: 14px;
}

.navbar-collapse.active {
  display: block;
  position: absolute;
  background-color: #fff;
  top: 76px;
  left: 0;
  padding: 20px;
  z-index: 1;
}

.logout-btn {
  outline: none;
  border: transparent;
  background: transparent;
}
.logo-image {
  object-fit: contain;
}

.nav-link {
  margin-right: 0.3rem;
}

.btnn {
  background-color: #cc5500;
  border: 1px solid #cc5500;
}

.btnn:focus {
  outline: none;
}
/* @media (min-width: 768px) {
  #navbarSupportedContent {
    margin-left: 100px;
  }
}*/
