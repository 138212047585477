.featured-property-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
}

.featured-property-card:hover {
  transform: translateY(-10px);
}

.property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-info {
  padding: 15px;
  text-align: left;
}

.property-info .title {
  font-size: 20px;
  color: #cc5500;
  margin-bottom: 10px;
}

.property-info .title span {
  font-size: 0.875rem;
  color: #666;
  float: right;
}

.location {
  font-size: 0.875rem;
  color: #999;
  margin-bottom: 10px;
}
.price-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.price {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin-bottom: 20px;
}

.meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 20px;
}

.meta span {
  font-size: 0.875rem;
  color: #666;
}

.details {
  display: flex;
  gap: 20px;
}

.details span {
  font-size: 1rem;
  color: #000;
}

.property-info .titlev2 {
  font-size: 15px;
}

.locationv2 {
  font-size: 0.8rem;
}

.price2 {
  font-size: 16px;
  font-weight: 800;
  color: #000;
  margin-bottom: 20px;
}
