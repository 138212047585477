.discover-help {
  text-align: center;
  padding: 40px 0px;
  margin-top: 30px;
}

.discover-help .title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 50px;
  color: #cc5500;
}

.help-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-top: 20px;
  
  
}

@media (min-width: 768px) {
  .help-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .help-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
