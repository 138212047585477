.home-service-container {
  background: linear-gradient(270deg, #f3e9e9 23.12%, #ffeacd 100%);
  position: relative;
}
.service-section {
  display: flex;
  flex-direction: column;
  position: relative;
}

.service-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 20px;
}

.service-text {
  max-width: 560px;
  padding-right: 60px;
  padding-left: 15px;
  margin-left: auto;
  margin-top: 7rem;
}

.service-text h2 {
  color: #cc5500;
  font-size: 28px;
  margin-bottom: 10px;
}

.service-text p {
  font-size: 16px;
  margin-bottom: 20px;
}

.service-buttons {
  display: flex;
  gap: 10px;
}

.service-btn {
  background-color: #cc5500;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.service-btn:hover {
  background-color: #b34d00;
}

.service-image-container {
  padding: 0;
  width: 868px;
  max-width: 50vw;
  height: 812px;
  max-height: 100vh;
  position: relative;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border-bottom-left-radius: 150px;
}

.hero-cta {
  position: absolute;
  bottom: 2%;
  left: 5%;
  background-color: #cc5500bd;
  padding: 15px;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  color: white;
  z-index: 99;
}

.hero-cta .title {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: left;
  line-height: 24px;
}

.hero-cta .description {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.cta-button {
  display: block;
  padding: 10px 20px;
  font-weight: 700;
  background-color: white;
  color: #cc5500;
  border-radius: 20px;
  text-align: center;
  width: max-content;
  margin-top: 10px;
  outline: none;
  border: none;
}
.cta-button:focus {
  outline: none;
  border: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
  }

  .service-text {
    padding-right: 0;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
  }
  .hero-cta {
    width: 100%;
    right: 15px;
    max-width: 300px;
  }
  .service-btn {
    padding: 10px;
    font-size: 16px;
  }

  .service-image-container {
    text-align: center;
    margin-top: 20px;
    max-width: 100vw;
    height: auto;
  }

  .service-check {
    text-align: center;
    margin-top: 20px;
  }
  .service-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

@media (min-width: 1024px) {
  .hero-content p {
    font-size: 1.5 rem;
  }

  .hero-container .hero-cta {
    max-width: 380px;
    bottom: 25%;
    left: 30px;
    padding: 10px;
    border-radius: 20px;
  }

  .hero-container .hero-cta .title {
    font-size: 18px;
    line-height: 1.5;
  }

  .hero-container .hero-cta .description {
    font-size: 14px;
    line-height: 1.5;
  }
}

@media (min-width: 1440px) {
  .service-text {
    max-width: 600px;
    margin-right: 120px;
    padding-left: 15px;
    padding-right: 20px;
    margin-left: auto;
  }
  .service-text {
    margin-top: 15rem;
  }
}
