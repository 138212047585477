.marg {
  margin-left: 50px;
  margin-bottom: 28px;
  border-color: #f9f5f7;
  border-style: none;
  border-width: thin;
}

.marg:hover {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation-name: chinedu;
  animation-duration: 0.25s;
  border-left: 8px solid #cc5500;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.property-cate {
 /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
 /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
 /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
 /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
 /* font-family: Georgia, 'Times New Roman', Times, serif; */
 font-family: 'Poppins', sans-serif;
 font-size: 14px;
  color: rgba(34, 34, 34, 0.65);
  font-weight: 400;
}

.prop_mobile {
  margin-top: 3rem;
}

.home-badge-header {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

@media (max-width: 480px) {
  .prop_mobile {
    margin-top: 410px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .prop_mobile {
    margin-top: 410px;
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .prop_mobile {
    margin-top: 410px;
  }
}