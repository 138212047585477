.userdashboardpage {
  background: #f5f5f5;
}
.buttonTab {
  padding: 30px 0;
}
.btn-xlarge {
  width: 100%;
  height: 110px;
  background: #f5efe5;
  border-radius: 6px;
  font-size: 18px;
}
.btn-xlarge.active {
  background: #cc5500;
  color: #fff;
}
.btn-xlarge:focus {
  outline: none;
  outline-color: transparent;
}
.btn-xlarge.btn-xlarge.active > span svg path {
  fill: #fff;
}
.buttonTab > button > span {
  display: block;
  padding-bottom: 20px;
}
.userdashboardpage-menu {
  padding-bottom: 50px;
}
.userdashboard_tabheader {
  font-size: 32px;
  color: rgba(19, 21, 22, 1);
  font-weight: 700;
}
.userdashboardpage-wrapper {
  padding-top: 40px;
}
.paymentoptions-card,
.paymentcalculator-card {
  padding: 60px 50px 60px 50px;
  background: #fff;
}
.paymentoptions-card > h1,
.paymentcalculator-card > h1 {
  font-size: 32px;
  color: rgba(19, 21, 22, 1);
  font-weight: 700;
}
.paymentoptions-card > p,
.paymentcalculator-card > p {
  padding: 20px 0;
  line-height: 30px;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 400;
}
.paymentoptions-card > .btn,
.paymentcalculator-card > .btn {
  padding: 13px 30px;
  background-color: #cc5500;
  border: 1px solid #cc5500;
}
.paymentcalculator-card {
  margin: 30px 0 120px 0;
}
.paymentcalculator-card > h1 {
  font-size: 30px;
}
.myuserprofilecard {
  padding-bottom: 150px;
}
.myuserprofile-header {
  padding-bottom: 50px;
  font-size: 24px;
  color: rgba(19, 21, 22, 1);
  font-weight: 700;
  line-height: 32px;
}
.userdetailscard {
  display: flex;
}
.userdetailscard-img {
  position: relative;
}
.userdetailscard-img > img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.userdetailscard-img .image-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.userdetailscard-desc {
  padding-left: 30px;
}
.userdetailscard-name > h1 {
  font-size: 18px;
  color: rgba(19, 21, 22, 0.9);
}
.userdetailscard-name > span {
  position: relative;
  top: -10px;
  color: rgba(19, 21, 22, 0.5);
  font-size: 12px;
  line-height: 18px;
}
.userdetailscard-kyc > span {
  display: block;
  font-size: 12px;
  color: rgba(19, 21, 22, 0.9);
}
.userdetailscard-kyc > span:nth-child(2) {
  margin: 6px 0;
}
.userdetailscard-kyc > button {
  padding: 8px 20px;
  margin: 30px 0;
  background: #cc5500;
  border-radius: 1px;
}
.userdetails-settings > h1 {
  font-size: 18px;
  color: rgba(19, 21, 22, 0.9);
  font-weight: 500;
}
.userdetails-settings > span {
  display: block;
  color: rgba(19, 21, 22, 0.6);
  font-size: 14px;
}
.send-tipsandguides {
  padding-top: 10px;
}
.pair {
  margin-top: 0.9rem;
  display: flex;
  flex-direction: row;
}
.first-pair {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.6);
}
.second-pair {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.6);
  padding-left: 0.5rem;
}
.image-upload {
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -10px;
  top: 90px;
  background: #cc5500;
  border: 2px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
}
.image-upload > input {
  display: none;
}
@media (min-width: 768px) {
  .userdetailscard-img > img {
    width: 150px;
    height: 150px;
  }
  .userdetailscard-kyc > span {
    font-size: 16px;
  }
  .userdetailscard-kyc > button {
    padding: 12px 30px;
    margin: 30px 0;
  }
}
