.feedback-card {
  padding: 10px;
  background: #f3e9e9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.feedback-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  margin-bottom: 10px;
  color: #cc5500;
}

.feedback-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #212529;
}
