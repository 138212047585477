.proformpage {
  background: #F2F5F7;
  font-family: 'Poppins', sans-serif;
}
.proformpage-subheader {
  padding: 20px 50px;
  color: rgba(19, 21, 22, 0.6);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.proformpage-card {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 120px;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-center > .btn {
  width: 340px;
  background-color: #cc5500;
  margin-top: 20px;
}
.flex-center > .btn > span {
  margin-left: 5px;
}
.socialflex-label {
  display: flex;
  align-items: center;
}
.socialflex-label > span {
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
  color: rgba(50, 54, 63, 0.8);
}
.socialflex {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.proformpage-form {
  padding: 0 20px;
}
.socialflex.facebook {
  background-color: rgba(59, 89, 152, 1);
}
.socialflex.twitter {
  background-color: rgba(85, 172, 238, 1);
}
.socialflex.instagram {
  background-color: rgba(69, 138, 190, 1);
}
.socialflex.linkedin {
  background-color: rgba(0, 122, 185, 1);
}
@media (min-width: 768px) {
  .proformpage-card {
    padding-left: 0;
    padding-right: 0;
  }
  .proformpage-form {
    padding: 0 0;
  }
}