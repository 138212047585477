.featured-properties {
  padding: 20px;
  margin-top: 30px;
}

.featured-properties .title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  margin-bottom: 10px;
}

.featured-properties .description {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #222222A6;
  margin-bottom: 30px;
}

.property-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)) !important;
  gap: 20px;
}
