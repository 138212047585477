.tipsandguides {
  font-family: 'Poppins', sans-serif;
  padding: 50px 0;
}
.tipsandguides-header {
  color: rgba(19, 21, 22, 1);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.tipsandguides-subheader {
  display: block;
  padding: 0 0 20px 0;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.65);
  font-weight: 400;
}
@media (min-width: 768px) {
  .tipsandguides {
    padding: 80px 0;
  }
}