.AllProperties__container {
  display: flex;
  padding: 0 1rem;
  margin: 1.5rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
