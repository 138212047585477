.advertplanscard {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}
.advertplanscard.big {
  padding: 50px 0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
}
.advertplanscard-content {
  padding: 20px;
}
.advertplanscard-content > span {
  display: inline-block;
  text-align: center;
  color: rgba(19, 21, 22, 0.6);
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.advertplanscard-center {
  text-align: center;
  color: rgba(19, 21, 22, 0.6);
  font-size: 20px;
  padding: 50px 0 0 0;
}
.subscriptionncard-price {
  margin-bottom: 10px;
}
.subscriptionncard-price > span:nth-child(1) {
  color: #cc5500;
  font-size: 32px;
  line-height: 40px;
}
.subscriptionncard-price > span:nth-child(2) {
  display: inline-block;
  position: relative;
  top: 20px;
  color: #cc5500;
  font-size: 44px;
  line-height: 64px;
}
.subscriptionncard-price > span:nth-child(3) {
  color: #cc5500;
  position: relative;
  top: 20px;
  font-size: 16px;
  line-height: 24px;
}

.subscriptioncard-merit > span:nth-child(2) {
  margin-left: 5px;
  color: rgba(19, 21, 22, 0.8);
  font-size: 14px;
}
.subscription-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.subscription-btn > .btn {
  width: 156px;
  background: #cc5500;
  border: 1px solid #cc5500;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 2px;
}