.form-check-input:checked {
  background-color: #cc5500;
  border-color: #cc5500;
}
.form-check-input:focus {
  border-color: #cc5500b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(204, 85, 0, 0.404);
}
.form-check-input {
  width: 30px;
  height: 30px;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(19, 21, 22, 0.3);
  -webkit-appearance: none;
  appearance: none;
}
.form-check-input[type=checkbox] {
  border-radius: 0;
}
.form-check-label {
  margin-top: 9px;
  margin-left: 5px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(19, 21, 22, 0.8);
}
.rec-swipable {
  gap: 10px;
}
.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}
.flex {
  display: flex;
}
.items-center {
  display: flex;
  align-items: center;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}
.block {
  display: block;
}
.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.app-red {
  color: #cc5500;
}
.app-bg-red {
  background-color: #cc5500;
}
.f-14 {
  font-size: 14px;
}
.f-32 {
  font-size: 2rem;
}
.white {
  color: #fff !important;
}
.underline-none {
  text-decoration: none;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-100 {
  margin-top: 100px;
}
.my-50 {
  margin: 50px 0;
}
.solid-black-2 {
  border: 2px solid #131516;
}
.bg-btn-black {
  background: #131516;
  color: #fff;
}
.bg-btn-black:hover {
  background-color: #222;
  color: #eee;
}
.color6 {
  color: rgba(19, 21, 22, 0.6);
}
.relative {
  position: relative;
}
.fs-14 {
  font-size: 14px !important;
  font-weight: 400;
  color: rgba(19, 21, 22, 0.7);
  line-height: 24px;
}
.fs-12 {
  font-size: 12px !important;
  font-weight: 400;
  color: rgba(19, 21, 22, 0.7);
  line-height: 24px;
}
.btn-outline-default {
  color: rgba(90, 91, 92, 0.6);
  background: #F8F8F8;
  border: 1px solid rgba(90, 91, 92, 0.4);
}
.btn-outline-orange {
  border: 1px solid #cc5500;
  color: #cc5500;
}
.btn-outline-orange:hover {
  background-color: #ee6b0e;
  color: #fff;
  border: 1px solid #ee6b0e;
}
.btn-orange {
  background-color: #cc5500;
  color: #fff;
}
.btn-orange:hover {
  background-color: #ec6d11;
  color:#fff;
}
.bg-white {
  background-color: #fff;
}