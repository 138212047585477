.auth {
  font-family: 'Poppins', sans-serif;
}
.app-logo {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.register-intro {
  color: rgba(19, 21, 22, 0.7);
  font-weight: 400;
  text-align: center;
  padding: 30px 20px 0 20px;
}
.social-btns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}
.social-btns-alt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.facebook-btn, .google-btn {
  width: 100%;
  padding: 10px 38px;
  font-weight: 500;
  background: rgba(37, 38, 40, 0.16);
  border-radius: 0;
}
.facebook-btn > span:first-child, .google-btn > span:first-child {
  margin-right: 10px;
}
.google-btn {
  margin-top: 10px;
}
.social-btns > button:hover {
  background-color: #eee;
}
.secondary-intro {
  padding: 20px;
  text-align: center;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 400;
}
.already-have-an-account {
  padding: 20px 0;
  text-align: center;
  color: rgba(19, 21, 22, 0.8);
  font-size: 14px;
  font-weight: 500;
}
.already-have-an-account > span > a {
  margin-left: 5px;
  color: #cc5500;
  text-decoration: none;
}
.register-check-label {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.7);
  font-weight: 400;
}
.btn-create-account {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #cc5500;
  font-size: 16px;
  font-weight: 600;
  border-radius: 2px;
  width: 100%;
}
.logo-image {
  width: 100px;
  height: 60px;
  object-fit: contain;
}
.forgot-password-link {
  padding: 15px 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}
.forgot-password-link > a {
  color: rgba(19, 21, 22, 0.7);
  text-decoration: none;
}
@media (min-width: 768px) {
  .social-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: nowrap;
  }
  .social-btns > .btn > span:last-child {
    position: relative;
    top: 1px;
  }
  .facebook-btn, .google-btn {
    width: auto;
    display: flex;
  }
  .google-btn {
    margin-top: 0;
  }
}