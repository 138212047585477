.pagination {
  margin: 20px 0 80px 0;
  background: #FFFFFF;
  border-radius: 2px;
}
.pagination > li {
  color: rgba(19, 21, 22, 0.8);
  font-weight: 600;
  text-decoration: none;
  border: 1px solid rgba(39, 182, 186, 0.6);
}

.pagination > li > a {
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
}

.pagination > li > a:focus {
  outline: none;
}

.pagination > .active {
  background: #cc5500;
  color: #FFFFFF;
  font-weight: 600;
  border: none;
}