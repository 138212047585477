.affordability-header {
  padding: 30px 0 60px 0;
}
.affordability-header > h1 {
  font-size: 32px;
  text-align: center;
}
.affordability-header > p {
  color: rgba(19, 21, 22, 0.8);
}
.calculated-outcome {
  padding: 50px 0;
}
.calculated-outcome > span {
  display: block;
  text-align: center;
}
.calculated-outcome > span:first-child {
  color: rgba(19, 21, 22, 0.8);
  font-size: 20px;
}
.calculated-outcome > .calculated-price {
  color: rgba(19, 21, 22, 1);
  font-size: 40px;
  padding: 20px 0;
  font-weight: 500;
}
.calculated-outcome > p {
  text-align: center;
  color: rgba(19, 21, 22, 0.8);
}