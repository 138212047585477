.hero-container {
  position: relative;
  background-image: url('/assets/new-hero-bg.jpeg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  max-height: 856px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  color: white;
  padding: 0 20px;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* For dark overlay */
}

.hero-content {
  z-index: 1;
  text-align: center;
  padding: 0px 20px;
  width: 100%;
  max-width: 1200px;
}

.hero-content h1 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 40px;
  margin-top: 7rem;
}

.hero-content p {
  margin-top: 10px;
  font-size: 1.25rem;
}

.search-filter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
}

.hero-container .hero-cta {
  position: absolute;
  bottom: 5%;
  left: 5%;
  background-color: #cc5500bd;
  padding: 5px;
  border-radius: 15px;
  width: 100%;
  max-width: 280px;
  box-sizing: border-box;
}

.hero-container .hero-cta .title {
  margin-bottom: 10px;
  font-size: 22px;
  text-align: left;
  line-height: 34px;
}

.hero-container .hero-cta .description {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
}

.cta-button {
  display: block;
  padding: 10px 20px;
  font-weight: 700;
  background-color: white;
  color: #cc5500;
  border-radius: 20px;
  text-align: center;
  width: max-content;
  margin-top: 10px;
  outline: none;
  border: none;
}
.cta-button:focus {
  outline: none;
  border: none;
}

@media (min-width: 768px) {
  .hero-content {
    height: auto;
    max-height: max-content;
  }

  .hero-container .hero-cta {
    max-width: 280px;
    bottom: 20%;
    left: 5%;
  }

  .cta-button {
    margin-top: 10px;
    font-weight: 700;
  }

  .hero-container .hero-cta .title {
    font-size: 18px;
    line-height: 34px;
  }

  .hero-container .hero-cta .description {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    height: 1400px;
  }
  .hero-content h1 {
    font-size: 2rem;
    margin-top: 2rem;
    line-height: 40px;
  }
  .hero-content p {
    line-height: normal;
  }
  .search-filter {
    margin-top: -3rem;
    top: 15rem;
  }
  .hero-container .hero-cta {
    max-width: 300px;
  }
  .hero-container .hero-cta .title {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .hero-container {
    padding: 0 40px;
  }

  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1.5 rem;
  }

  .hero-container .hero-cta {
    max-width: 380px;
    bottom: 25%;
    left: 30px;
    padding: 10px;
    border-radius: 20px;
  }

  .hero-container .hero-cta .title {
    font-size: 18px;
    line-height: 1.5;
  }

  .hero-container .hero-cta .description {
    font-size: 14px;
    line-height: 1.5;
  }
}
