.financebanner {
  width: 100%;
  height: 250px;
  position: relative;
  background: rgba(19, 21, 22, 0.8);
  font-family: 'Poppins', sans-serif;
}
.financebanner > img {
  width: 100%;
  height: 250px;
  object-fit: fill;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(19, 21, 22, 0.5);
}

.financebanner-content {
  padding-top: 100px;
}
.financebanner-content > h1 {
  font-size: 60px;
  color: #fff;
  text-align: center;
  line-height: 66px;
  font-weight: 500;
}
.financebanner-content > p {
  font-size: 14px;
  text-align: center;
  color: #fff;
}
