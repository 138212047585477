.newlistingsforsale {
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  padding: 50px 0;
}
.newlistings-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.newlistings-tabs {
  width: 100%;
}
.newlistingforsale-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
.newlistings-subheading {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.65);
  font-weight: 400;
}
@media (min-width: 768px) {
  /* .newlistingsforsale {
    padding: 50px 0;
  } */
  .newlistings-flex {
    flex-wrap: nowrap;
  }
  .newlistings-tabs {
    width: auto;
  }
}

@media (max-width: 830px) {
  .container-mobile {
    margin-top: 0px;
  }
}