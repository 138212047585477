.filter__container button {
  margin-right: 2rem;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 1rem;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1.5rem;
}

button.active {
  /* background-color: #000; */

  color: #fff;
}
