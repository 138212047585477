.homeservicespage {
  position: relative;
  font-family: 'Poppins', sans-serif;
}
.navigation-tabs {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 80px;
}
.navigation-tabs > .tab-list {
  display: flex;
  list-style: none;
  border-color: transparent;
}

.react-tabs__tab--selected {
  border-color: transparent;
}
.navigation-tabs > .tab-list .tab-btn {
  padding: 10px 30px;
}
.navigation-tabs > .tab-list > .rwt__tab[aria-selected='true'] {
  position: relative;
  color: rgba(19, 21, 22, 0.8);
  border-bottom: none;
}
.browse-products {
  height: 400px;
  padding: 20px 0 0 0;
}
.browse-products-agents {
  height: 450px;
}
.deptcard {
  width: 350px !important;
  height: 270px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 2px;
}
.deptcard > img {
  width: 170px;
  height: 170px;
  object-fit: cover;
}
.deptcard > a {
  display: block;
  padding-top: 30px;
  text-decoration: none;
  color: rgba(19, 21, 22, 0.8);
  font-size: 14px;
  font-weight: 500;
}
.vendorcard {
  width: 350px !important;
  padding: 20px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(243, 243, 243, 1);
}
.vendorcard > a {
  display: block;
  padding-top: 15px;
  text-decoration: none;
  text-align: center;
  color: rgba(19, 21, 22, 0.8);
  font-size: 14px;
  font-weight: 500;
}
.vendorcard > img {
  width: 100%;
  height: 197px;
  object-fit: cover;
}
.agentcard {
  /* width: 100%; */
  padding: 20px;
  background-color: rgba(243, 243, 243, 1);
}
.agentcard-content {
  padding-top: 20px;
}
.agentcard-content > h3 {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 500;
}
.agentcard-content > span {
  display: block;
  color: rgba(19, 21, 22, 0.6);
  padding-bottom: 3px;
  font-size: 13px;
  font-weight: 400;
}
.agentcard-content > .btn {
  color: rgba(218, 37, 29, 1);
  font-size: 14px;
  padding-right: 0;
}
.agentcard > img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
}
.flex-browse {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.flex-browse > h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(19, 21, 22, 1);
}
.flex-browse > a,
.flex-browse > span {
  font-size: 14px;
  color: rgba(90, 91, 92, 0.7);
  text-decoration: none;
  font-weight: 600;
}
.homeservices-container {
  padding-bottom: 100px;
}

.section-header {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: #212529;
}
.all-services-directory {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.service-directory-card {
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 10px 16px;
  border: 1px solid #00000073;
}
.service-directory-card .title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}
.service-directory-card .service-item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.service-directory-card .service-item .count {
  color: #0f05a0;
}

.how-it-works-action {
  margin-top: 40px;
  margin: auto;
}
.how-it-works-action .title {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: #cc5500;
}
.how-it-works-action .description {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.book-service-button {
  background-color: #cc5500;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.book-service-button:hover {
  background-color: #ff9a5c;
}

.selected-tab {
  width: Hug (132px) px;
  height: Fill (47px) px;
  padding: 10.5px 31px 11.5px 31px;
  gap: 0px;
  border-radius: 5px 5px 0px 0px;
  background: #cc5500bd;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #1e1e1e;
}
.tab-list{
  overflow-x: auto;
  text-wrap: nowrap;
}

@media (min-width: 768px) {
  .flex-browse > h1 {
    font-size: 24px;
  }
}

.select-flex {
  display: flex;
  justify-content: flex-start;
}
