.secondarybanner {
  width: 100%;
  height: 450px;
  position: relative;
  background: rgba(19, 21, 22, 0.8);
  font-family: 'Poppins', sans-serif;
}
.secondarybanner > img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.secondarybanner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(19, 21, 22, 0.8);
}
.centralize-banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondarybanner-content > h1 {
  padding: 15px 0 0 0;
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}
.secondarybanner-content > p {
  color: #fff;
  font-size: 14px;
  padding: 20px 60px;
  text-align: center;
  font-weight: 400;
}
@media (min-width: 768px) {
  .secondarybanner-content > h1 {
    font-size: 60px;
  }
}
