.homeservicesbanner {
  width: 100%;
  height: 250px;
  position: relative;
  background: rgba(19, 21, 22, 0.8);
  font-family: 'Poppins', sans-serif;
}
.homeservicesbanner > img {
  width: 100%;
  height: 250px;
  object-fit: fill;
}
.homeservices-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.homeservices-search {
  width: 100%;
}
.homeservices-search-btn {
  width: 100%;
}
.homeservices-search-btn > a {
  display: block;
  padding: 11px 15px;
  background-color: #cc5500;
  border: 1px solid #cc5500;
}
.secondarybanner-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(19, 21, 22, 0.5);
}
@media (min-width: 768px) {
  .homeservices-flex {
    flex-direction: row;
  }
  .homeservices-search {
    width: 79%;
  }
  .homeservices-search-btn {
    width: 20%;
  }
  .homeservices-search-btn > .btn {
    padding: 11px;
  }
}
