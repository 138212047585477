.propertycard {
  position: relative;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(218, 37, 29, 0.3);
  border-radius: 8px;
}

.propertycard-image {
  width: 100%;
  height: 260px;
  position: relative;
}
.propertycard-image > img {
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.propertycard-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; 
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.445);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.propertycard-overlay_posted-time {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  width: 94px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cc5500;
  color: #fff;
  border-radius: 8px 0px 0px 0px;
}
.propertycard-overlay_desc-city {
  width: 100%;
  font-size: 24px;
  color: #fff;
  text-align: center;
}
.propertycard-overlay_desc-type {
  width: 100%;
  font-size: 16px;
  color:rgba(255, 255, 255, 0.781);
  text-align: center;
}
.propertycard-overlay_desc-price {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.propertycard-desc {
  padding: 30px 20px;
}
.propertycard-header {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.propertycard-features {
  display: flex;
  justify-content: space-between;
}
.propertycard-features > span {
  font-size: 14px;
  color: #131516c0;
  font-weight: 500;
}
.propertycard-btn {
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
}
.propert-status {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 12px;
  color: rgba(19, 21, 22, 0.6);
  line-height: 20px;
}
.availability-status {
  color: rgb(63, 236, 19);
}
.propertycard-reaction > span {
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.propertycard-reaction {
  position: relative;
  bottom: -30px;
}
@media (min-width: 768px) {
  .propertycard {
    margin-top: 0;
  }
}