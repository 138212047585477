.tipscard {
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(218, 37, 29, 0.15);
  border-radius: 8px;
}
.tipscard-image {
  width: 100%;
  height: 220px;
  position: relative;
}
.tipscard-image > img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.tipscard-desc {
  padding: 20px;
}
.tipscard-date {
  font-size: 12px;
  color: rgba(34, 34, 34, 0.9);
  font-weight: 500;
}
.tipscard-header {
  display: block;
  padding: 20px 0 8px 0;
  font-size: 16px;
  color: rgba(34, 34, 34, 1);
  text-decoration: none;
  font-weight: 500;
  line-height: 22px;
}
.tipscard-header:hover {
  color: #000;
;
}
.tipscard-content {
  color: rgba(34, 34, 34, 0.9);
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
@media (min-width: 768px) {
  .tipscard {
    margin-top: 0;
  }
}