.featuredlisting {
  font-family: 'Poppins', sans-serif;
  padding: 50px 0;
  background-color: #f1f3f3;
}
.featuredlisting-header > h1 {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}
.flex-tab {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.tablist {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.tab-btn {
  font-weight: 500;
  line-height: 1.5;
  color: rgba(19, 21, 22, 0.4);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.55rem;
  font-size: 14px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.tab-btn:focus {
  color: #212529;
  border-bottom: 2px solid #cc5500;
  outline: none;
  border-radius: 0;
}
@media (min-width: 768px) {
  .featuredlisting {
    padding: 80px 0;
  }
}