.dashboardbanner {
  width: 100%;
  height: 250px;
  position: relative;
  background: rgba(19, 21, 22, 0.8);
  font-family: 'Poppins', sans-serif;
}
.dashboardbanner > img {
  width: 100%;
  height: 250px;
  object-fit: fill;
}
.dashboardbanner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(19, 21, 22, 0.8);
}
.dashboardbanner-header {
  padding-top: 40px;
  font-size: 36px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  line-height: 66px;
}
.dashboardbanner-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 70px 0 0 0;
}
.dashboardbanner-userintro > span {
  display: block;
}
.dashboardbanner-userintro > span:first-child {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.dashboardbanner-userintro > span:last-child {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
}
.dashboardbanner-btn {
  display: flex;
  align-items: center;
}
.dashboardbanner-btn > .btn {
  margin-top: 20px;
  background: #cc5500;
  border: 1px solid #cc5500;
}
@media (min-width: 768px) {
  .dashboardbanner-header {
    padding-top: 60px;
    font-size: 60px;
    text-align: center;
  }
  .dashboardbanner-flex {
    display: flex;
    justify-content: space-between;
    padding: 45px 0 0 0;
  }
  .dashboardbanner-btn > .btn {
    margin-top: 0;
  }
}

.username {
  font-size: larger;
}