.submitlistingpage {
  background: #F2F5F7;
  font-family: 'Poppins', sans-serif;
}
.submitlistingpage-card {
  padding-bottom: 120px;
}
.submitlistingpage-header {
  padding: 60px 0 10px 0;
  text-align: center;
  font-size: 32px;
  color: rgba(19, 21, 22, 1);
  font-weight: 700;
  line-height: 42px;
}
.submitlistingpage-subheader {
  text-align: center;
  font-size: 14px;
  color: rgba(19, 21, 22, 0.8);
  line-height: 22px;
}
.submitlist-uploader {
  width: 100%;
  height: 167px;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #F5F5F5;
  border: 0.1px solid rgba(19, 21, 22, 0.2);
  cursor: pointer;
}
.submitlist-uploader>input {
  display: none;
}
.submitlist-uploader > span {
  display: block;
  padding-top: 30px;
  text-align: center;
}
.submitlistingpage-btns {
  display: flex;
  justify-content: flex-end;
}
.flexend-btns > .btn-outline {
  margin-right: 10px;
  padding: 12px 30px;
  color: rgba(90, 91, 92, 0.7);
  border: 1px solid rgba(90, 91, 92, 0.7);
}
.flexend-btns > .btn-danger {
  padding: 12px 30px;
  background-color: #cc5500;
}