.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 5px;
  background-color: #cae8ca;
  border: 2px solid #4caf50;
  background-color: red;
}
.select-flex {
  width: 100%;
  display: block;
}
.select-flex-item > select {
  width: 100%;
  margin-bottom: 10px;
}
.select-label {
  color: #fff;
}
.price-label {
  color: #fff !important;
}
.select-btn {
  margin-top: 32px;
}
.select-btn > button {
  width: 100%;
  padding: 11px 0;
  border-radius: 0;
  background-color: #cc5500;
  border: 1px solid #cc5500;
}

@media (min-width: 768px) {
  .select-flex {
    display: flex;
    justify-content: space-between;
  }
  .select-flex-item > select {
    width: 155px;
  }
  .select-btn > button {
    width: 185px;
    padding: 11px 0;
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .select-flex {
    width: initial;
  }
  .select-btn {
    margin-top: 12px;
  }
}
