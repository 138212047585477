.tipsdetailspage {
  font-family: 'Poppins', sans-serif;
}
.tipsdetailspage-banner > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.tipsdetails-flex {
  display: flex;
}
.tipsdetails-author {
  color: #cc5500;
  font-size: 12px;
}
.tipsdetails-date {
  margin-left: 60px;
  font-size: 12px;
}
.tipsdetails-date > span {
  margin-left: 10px;
}
.tipsdetails > p {
  color: rgba(19, 21, 22, 0.8);
  line-height: 28px;
}
.tipsdetails > h1 {
  padding: 50px 0 10px 0;
  color: rgba(34, 34, 34, 1);
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}
.tipsdetails-content {
  padding: 20px 0 60px 0;
}
.tipsdetails-recentarticles_header {
  padding: 20px 0 15px 0;
  color: rgba(19, 21, 22, 1);
  font-size: 20px;
  font-weight: 700;
}
.tipsdetails-relatedarticles_header {
  padding: 30px 0 15px 0;
  color: rgba(19, 21, 22, 1);
  font-size: 20px;
  font-weight: 700;
}
.tipsdetails-footer {
  padding-top: 120px;
}
@media (min-width: 768px) {
  .tipsdetailspage-banner > img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .tipsdetails-date {
    margin-left: 120px;
    font-size: 12px;
  }
}
