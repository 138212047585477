.SingleProfessionalPage {
  font-family: 'Poppins', sans-serif;
}
.singlepropage-tab {
  padding: 20px 0;
}
.singlepropage-user > img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.singlepropage-user > p {
  color: rgba(19, 21, 22, 0.6);
}
.singlepropage-slider {
  background-color: rgba(29, 29, 29, 1);
  padding: 80px 0;
}
.singlepropage-slider > .container > h1 {
  font-size: 24px;
  padding-bottom: 40px;
  color: #fff;
  text-align: center;
}
.singlepropage-card {
  width: 364px;
  height: 364px;
  position: relative;
}
.singlepropage-card > img {
  width: 364px;
  height: 364px;
  object-fit: cover;
}
.singlepropage-item {
  width: 364px !important;
}
.singlepropage-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}
.singlepropage-overlay > h1 {
  color: #fff;
  font-size: 20px;
}
.singlepropage-overlay > a {
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 20px;
  font-size: 14px;
  text-decoration: none;
}
.singlepropage-inquiry {
  margin: 60px 0;
}
.singlepropage-inquiry_card {
  position: relative;
  width: 100%;
  height: 450px;
}
.singlepropage-inquiry_card > img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.singlepropage-inquiry_contact {
  padding-top: 20px;
}
.singlepropage-inquiry_contact > h1 {
  font-size: 32px;
}
.singlepropage-quotes {
  padding: 50px 0;
}
.singlepropage-quotes > h1 {
  font-size: 32px;
  padding: 30px 0 50px 0;
  text-align: center;
  font-weight: 300;
}
.customer-quote_image {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}
.quotes-box > p {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.6);
  padding: 30px 0 10px 0;
  text-align: center;
}
.quotes-box > span {
  display: block;
  text-align: center;
  color: rgba(19, 21, 22, 0.5);
  font-size: 12px;
}
.quotes-box > h1 {
  padding-top: 10px;
}
.loadmore-btn {
  padding: 40px 0;
}
.loadmore-btn > button {
  width: 500px;
  height: 50px;
  color: rgba(19, 21, 22, 0.8);
  background-color: rgba(229, 229, 229, 1);
}
.loadmore-btn > button:hover {
  background-color: rgba(229, 229, 229, 0.8);
  transition: all .3s ease;
}
.singlepropage-ratings {
  margin-bottom: 100px;
}