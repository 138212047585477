
.custom-modal {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.438);
  overflow-y: hidden;
}
.custom-modal-content-md,
.custom-modal-content-sm {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
}
.custom-modal-content {
  max-height: calc(100vh - 160px);
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 80%;
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  animation-name: modalopen;
  animation-duration: 1500ms;
}
.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.01);
  padding: 15px 20px;
  font-size: 18px;
  color: #444;
  font-weight: 400;
}
.modal-header-text {
  font-size: 22px;
  font-weight: 600;
}
.modal-body {
  padding: 20px;
}
.modal-footer {
  padding: 15px 20px;
  background-color: rgba(0, 0, 0, 0.01);
}
.modal.active {
  display: block;
}

.close-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  right: 5px;
  top: 5px;
  border-radius: 50%;
}

.close-modal:hover,
.close-modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  background-color: #eee;
} 

@media screen and (min-width: 769px) {
  .modal-content {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 650px;
  }
  .modal-content-md {
    margin: 0 auto;
    width: 750px;
  }
  .modal-content-sm {
    margin: 0 auto;
    width: 500px;
  }
}

@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 
