.partner-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #fff;
}

.partner-section .header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.partner-section .description {
  font-size: 14px;
  color: #222222A6;
  margin-bottom: 30px;
}

.partner-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.partner-logos img {
  max-width: 150px;
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .partner-logos {
    flex-direction: column;
    gap: 20px;
  }

  .partner-logos img {
    max-width: 100px;
  }
}
