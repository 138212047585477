.tipsandguidespage {
  position: relative;
  font-family: 'Poppins', sans-serif;
}
.tipsandguidespage-header {
  padding: 80px 0 5px 0;
  font-size: 32px;
  font-weight: 700;
  color: rgba(19, 21, 22, 1);
  line-height: 46px;
}
.tipsandguidespage-span {
  display: block;
  padding: 0 0 10px 0;
  font-size: 14px;
  color: rgba(19, 21, 22, 0.8);
}