.bg {
  color: #a8d9ff80;
}
.category-select {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .category-select {
    margin-bottom: 5px;
  }
}