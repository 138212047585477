.home-type-container {
  margin: 30px auto;
}

.home-type-container .tab-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  border-bottom: 1px solid rgb(218, 212, 212);
  width: 100%;
  max-width: max-content;
  margin: 10px auto;
}
.tab-header label {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
}
.home-type-container .tab-header .active-tab {
  border-bottom: 2px solid rgb(99, 99, 99);
}

.home-type-container .approval-title {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.home-type-container .approval-description {
  text-align: center;
  color: gray;
  font-size: 16px;
}
.home-type-container .approval-label {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.home-type-container .home-type-card {
  border: 1px solid #2b2b2b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  cursor: pointer;
}
.home-type-container .home-type-card .title {
  font-size: 16px;
  text-wrap: wrap;
}
.home-type-container .home-type-card:hover {
  background: rgb(247, 243, 243);
}
.home-type-card.selected {
  background: #cc5500;
  color: white;
  border: 1px solid #cc5500;
}

@media screen and (max-width: 500px) {
  
  .tab-header label {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
  }
  .home-type-container .tab-header {
    gap: 1rem;
    margin: 5px auto;
  }
  .home-type-container .home-type-card .title {
    font-size: 14px;
    font-weight: 500;
    text-wrap: wrap;
  }
  .home-type-container .approval-label {
    font-size: 16px;
   
  }
  .home-type-container .approval-title {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
  }
}
