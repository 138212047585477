.how-it-works {
  padding: 58px 0px;
  position: relative;
}

.steps {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
}

.how-it-works-action {
  margin-top: 40px;
  margin: auto;
}
.how-it-works-action .title {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: #cc5500;
}
.how-it-works-action .description {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.book-service-button {
  background-color: #cc5500;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.book-service-button:hover {
  background-color: #ff9a5c;
}

@media (max-width: 768px) {
  .how-it-works-action .title {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 700;
  }
  .how-it-works-action .description{
    font-size: 20px;
  }
  .how-it-works .text-section{
    width: 100% !important;
    
  }
}

@media (min-width: 768px) {
  .how-it-works-container {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .steps {
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
  }

  .how-it-works-action {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .steps {
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
  }
}
