.properties-page {
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
}
.properties-page-header > h1 {
  padding: 30px 20px 0 0;
  font-size: 20px;
  color: rgba(19, 21, 22, 1);
  font-weight: 700;
}
.properties-page-header > span {
  font-size: 14px;
}
.card-container {
  padding-top: 30px;
  position: relative;
}

.filter-section {
  background-color: #13151680;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
  margin: auto;
  width: fit-content;
  margin-top: 20px;
}
.affordability-banner {
  background-color: #cc550014;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  padding: 24px;
}
.affordability-banner .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: #cc5500;
}
.affordability-banner .description {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.map-section {
  margin-bottom: 6rem;
}

.map-section img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.property-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)) !important;
  gap: 20px;
}
.filter-container {
  overflow: hidden;
  padding: 5px;
}

@media (min-width: 768px) {
  .properties-page-header > h1 {
    padding: 100px 50px 0 0;
    font-size: 20px;
    color: rgba(19, 21, 22, 1);
    font-weight: 700;
  }
  .aux-margin {
    margin-bottom: 30px;
  }
  .card-container {
    width: 100%;
    position: sticky;
    margin-right: 100%;
    top: 0;
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .secondarybanner {
    min-height: 750px;
    position: relative;
    /* margin-top:80px; */
    /* display : none; */
  }
}

@media (max-width: 767px) {
  .secondarybanner {
    height: 350px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 736px) {
  .secondarybanner {
    height: 350px;
  }
}

@media (min-width: 1024px) {
}
