.categoryfilter {
  margin-top: 0rem;
  font-family: 'Poppins', sans-serif;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(218, 37, 29, 0.3);
  border-radius: 8px;
  width: 100%;
}
.categoryfilter > h1 {
  padding: 20px 0;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background-color: rgba(19, 21, 22, 1);
  font-weight: 500;
  border-radius: 8px 8px 0 0;
}
.filtergroup-input-radio {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.filtergroup-button {
  width: 100%;
  padding: 12px 20px;
  display: block;
  position: relative;
  cursor: pointer;
  color: #9aa0ac;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  border-bottom: 0.5px solid rgba(19, 21, 22, 0.4);
  /* min-width: 2.375rem; */
}
.filtergroup-item:last-child > .filtergroup-button {
  border-bottom: none;
}
.filtergroup-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.filter-checkmark {
  opacity: 0;
  position: absolute;
  top: 13px;
  right: 20px;
}
.filtergroup-input-radio:checked + .filtergroup-button {
  z-index: 1;
  color: #cc5500;
}
.filtergroup-input-radio:checked + .filter-checkmark {
  opacity: 1;
}
