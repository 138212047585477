
.input-wrapper {
  position: relative;
}

.moneyFormat-input {
  background: transparent;
  height: 0;
  width: 0;
}

.moneyFormat-text {
  display: flex;
  align-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 16px;
  z-index: 1;
  color: #5e6c84;
  background: #fff;
}

