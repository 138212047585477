.paymentmethodcard {
  background: #FFFFFF;
  margin-left: 10px;
    margin-right: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}
.paymentmethodcard-content {
  padding: 60px 30px;
}
.paymentmethodcard-content >  p{
  padding: 50px 10px 0px 10px;
  color: rgba(19, 21, 22, 0.6);
  font-size: 14px;
  text-align: center;
}
.paymentmethodcard-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.paymentcard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 10px;
  margin-bottom: 20px;
  background: #F9F9F9;
  border: 0.5px solid rgba(218, 37, 29, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
}
.paymentcard-content > span {
  color: rgba(19, 21, 22, 1);
  font-weight: 600;
  line-height: 24px;
  margin-left: 10px;
}
.selected-check-mark {
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  right: -6px;
  top:-6px;
  border: 2px solid #cc5500;  
  border-radius: 50%;
  opacity: 0;
}

button.selected-gateway {
  outline: none;
  border: 2px solid #cc5500;
}

.selected-gateway .selected-check-mark {
  opacity: 1;
  transition: opacity .1s ease;
}

.paymentmethodcard-btn {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.paymentmethodcard-btn > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 50px;
  background: #cc5500;
  border: 1px solid #cc5500;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.paymentmethodcard-btn > .btn > span {
  margin-left: 5px;
}
@media (min-width: 768px) {
  .paymentmethodcard {
    margin-left: 0;
    margin-right: 0;
  }
  .paymentmethodcard-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .paymentcard {
    width: 164px;
    height: 80px;
    margin-bottom: 0;
  }
}