.calculator-section {
  padding: 50px 0;
  background: rgba(242, 245, 247, 1);
}
.form-flex {
  display: flex;
  justify-content: space-between;
}
.form-flex > .mb-3 {
  width: 90%;
}
.form-flex > .mb-3 > input {
  width: 90%;
}

.form-flex > .mb-3 > select {
  width: 90%;
}
.calculator-desc {
  padding-top: 40px;
}
.calculator-desc > h1 {
  color: rgba(19, 21, 22, 1);
  font-size: 24px;
  font-weight: 700;
}
.calculator-desc > p {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.8);
  font-weight: 400;
}
.calc-label {
  color: rgba(90, 91, 92, 1);
  font-size: 14px;
  font-weight: 500;
}
.calculator-form > button {
  margin-top: 10px;
  padding: 13px 40px;
  background-color: #cc5500;
  border: 1px solid #cc5500;
}
.calculator-form > button:hover {
  background-color: #cf6519;
  border: 1px solid #cf6519;
}
.calculator-image > img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  border-radius: 100%;
}
@media (min-width: 768px) {
  .calculator-image > img {
    width: 100%
  }
}