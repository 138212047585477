.pricefilter {
  font-family: 'Poppins', sans-serif;
  background: #FFFFFF;
  margin: 0px 0;
  box-shadow: 0px 4px 8px rgba(218, 37, 29, 0.3);
  border-radius: 8px;
}
.pricefilter > h1 {
  padding: 20px 0;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background-color: rgba(19, 21, 22, 1);
  font-weight: 500;
  border-radius: 8px 8px 0 0;
}
.pricefilter-content {
  padding: 20px;
}
.pricefilter-flex {
  display: flex;
  justify-content: space-between;
}
.pricefilter-flex_item {
  width: 100%;
}
.pricefilter-flex_item:nth-child(2) {
  margin-left: 10px;
}
.pricefilter-flex_item > .form-select {
  width: 100%;
  flex-basis: 1;
  flex-grow: 1;
}
.pricefilter-label {
  font-size: 14px;
  color: rgba(90, 91, 92, 1);
  font-weight: 500;
}
.pricefilter-btn_container {
  width: 100%;
  padding-top: 15px;
}
.pricefilter-btns {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  border-radius: 2px;
}
.pricefilter-btn_container:nth-child(2) {
  margin-left: 10px;
}
.pricefilter-btns > button, .pricefilter-btns > .pricefilter-count {
  width: 100%;
}
.pricefilter-btns > .pricefilter-count {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.btn-pricefilter {
  background: #F0F0F0;
  padding: .375rem .4rem;
  font-size: 1rem;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
}