* {
  padding: 0;
  box-sizing: border-box;
}
.doc-tied-property {
  padding-top: 2rem;
}

.doc-tied-property > .table {
  padding: 2rem 0;
  margin-left: 10%;
  width: 80%;
  justify-content: center;
}

table tbody {
  cursor: pointer;
}
