body {
  font-family: 'Poppins', sans-serif;
}
.br-0 {
  border-radius: 0 !important;
}
.br-2 {
  border-radius: 2px;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.mr-0 {
  margin-right: 0;
}
.ml-0 {
  margin-left: 0;
}
.right-aligned-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.centralize-div {
  display: flex;
  justify-content: center;
}

.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.h-400 {
  height: 400px;
}
.rwt__tab[aria-selected='true'] {
  position: relative;
  color: rgba(19, 21, 22, 0.8);
  border-bottom: 2px solid #cc5500;
}
.misc-date {
  display: block;
  font-size: 12px;
  color: rgba(19, 21, 22, 0.6);
  font-weight: 500;
  margin-bottom: 5px;
}
