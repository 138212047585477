.termspage {
  position: relative;
  font-family: 'Poppins', sans-serif;
}
.termspage-container {
  padding: 20px 20px;
  margin: 50px 0;
  background-color: #fff;
  border-radius: 3px;
}
.termspage-container > h1 {
  text-align: center;
  padding: 30px 0;
  color: #333;
}
.termspage-container > h2 {
  font-size: 18px;
  font-weight: 500;
}
.termspage-content > h1 {
  font-size: 16px;
  padding-top: 20px;
}
.termspage-content > h2 {
  font-size: 16px;
  padding-top: 20px;
  font-weight: 600;
  text-decoration: underline;
}
.termspage-content > p > span {
  font-weight: 600;
}
.termspage-content > p {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.9);
}
.termspage-content > ul {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.9);
}
.termspage-content > span {
  text-decoration: underline;
  font-size: 14px;
  font-style: italic;
  color: rgba(34, 34, 34, 0.9);
}
@media (min-width: 768px) {
  .termspage-container {
    padding: 40px 50px;
    margin: 50px 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
}
