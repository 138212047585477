.staring-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.staring-container .star{
  margin-left: 4px;
  margin-right: 4px;
}

.staring-container .star-rating-filled{
  fill: gold;
}

.staring-container .star-rating-default{
  fill: white;
}


