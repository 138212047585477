.advertuploadpage {
  background: #F3F3F3;
  font-family: 'Poppins', sans-serif;
}
.advertuploadpage-content > h1 {
  text-align: center;
  color: rgba(19, 21, 22, 0.9);
  font-size: 32px;
  padding-top: 50px;
}
.advertuploadpage-content > p {
  text-align: center;
  color: rgba(19, 21, 22, 0.6);
  padding: 0 10px;
  font-size: 14px;
}
.advertuploadpage-content > form {
  padding-top: 50px;
}
.advertuploadpage-content > form label {
  color: rgba(19, 21, 22, 0.6);
  font-size: 14px;
}
.file-format {
  font-size: 12px;
  color: rgba(19, 21, 22, 0.6);
  padding: 8px 10px;
  background: #E9E9E9;
border-radius: 6px;
}
@media (min-width: 768px) {
  .advertuploadpage-content > p {
    padding: 0 100px;
  }
}


.advertuploadpage {
  background: #F3F3F3;
  font-family: 'Poppins', sans-serif;
}
.advertuploadsucess-content > h1 {
  text-align: center;
  color: rgba(19, 21, 22, 0.9);
  font-size: 32px;
  padding-top: 70px;
  font-weight: 400;
}
.advertuploadsucess-content > p {
  text-align: center;
  color: rgba(19, 21, 22, 0.6);
  padding: 10px 10px 20px 10px;
  font-size: 14px;
}
.advertuploadsucess-card {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 50px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
}
.advertuploadsucess-card  > h1 {
  text-align: center;
  font-size: 24px;
  padding-top: 16px;
}
.advertuploadsucess-card > p {
  color: rgba(19, 21, 22, 0.6);
  padding: 0 50px;
  text-align: center;
}
.advertuploadsucess-card > .flex-center {
  padding-top: 20px;
}