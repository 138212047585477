.pre-approval-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.pre-approval-image img {
  width: 100%;
}

.pre-approval-content {
  text-align: center;
}

.pre-approval-content .title {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.pre-approval-content .description {
  font-size: 18px;
  margin-bottom: 20px;
  color: #1a1816;
}

.pre-approval-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #cc5500;
  color: white;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
  outline: none;
  border: none;
}

.pre-approval-button:hover {
  background-color: #a64400;
}

.pre-approval-button:active {
  background-color: #a64400;
}

@media (min-width: 768px) {
  .pre-approval-container {
    flex-direction: row;
    align-items: center;
    padding: 40px;
  }

  .pre-approval-image {
    flex: 1;
  }

  .pre-approval-content {
    text-align: left;
    padding-left: 40px;
  }

  .pre-approval-content .title {
    font-size: 2rem;
    margin-top: 25px;
    
  }
}
