.privacypage {
  position: relative;
  font-family: 'Poppins', sans-serif;
}

.privacypage-container {
  padding: 20px 20px;
  margin: 50px 0;
  background-color: #fff;
  border-radius: 3px;
}
.privacypage-container > h1 {
  text-align: center;
  padding: 30px 0;
  color: #333;
}
.privacypage-container > h2 {
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .privacypage-container {
    padding: 40px 50px;
    margin: 50px 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
}
