.paymentoptionspage {
  font-family: 'Poppins', sans-serif;
  background: #fff;
}
.breadcrumb-wrapper {
  display: flex;
  padding: 20px 0;
  align-items: center;
}
.breadcrumb-wrapper > a {
  display: block;
  color: rgba(19, 21, 22, 0.8);
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.breadcrumb-wrapper > span {
  display: inline-block;
  padding: 0 10px;
}
.breadcrumb-wrapper > a:not(:last-child) {
  color: #cc5500;
}
.paymentoptionspage-header {
  font-size: 24px;
  text-align: center;
  padding-top: 30px;
  font-weight: 700;
}
.payment-tablist {
  padding-top: 10px;
}
.payment-tablist > .tab-btn {
  font-size: 14px;
}
.mortgage-header > h1 {
  font-size: 18px;
  font-weight: 700;
}
.mortgage-header {
  padding-top: 0;
}
.mortgage-header > span {
  font-size: 14px;
  color: rgba(19, 21, 22, 0.6);
}
.mortgagerate-card {
  margin: 15px 0;
  background: rgba(242, 245, 247, 1);
  padding: 20px;
  border-radius: 6px;
}
.mortgagerate-card > span {
  display: block;
  text-align: center;
  color: rgba(19, 21, 22, 0.9);
  font-weight: 400;
}
.mortgagerate-card > h1 {
  padding-top: 10px;
  font-size: 46px;
  text-align: center;
  color: rgba(173, 9, 187, 1);
  font-weight: 400;
}
.mortgagerate-indicator {
  display: flex;
  justify-content: center;
}
.ratesbtn-wrapper {
  padding-top: 20px;
  padding-bottom: 30px;
}
.ratesbtn-wrapper > button {
  padding: 12px 40px;
}
.mortgageoffering-companies {
  padding: 50px 15px 100px 15px;
}
.mortgage-companies_card {
  width: 100%;
  height: 250px;
  margin: 10px 0 10px 0;
  background: rgba(242, 245, 247, 1);
  border-radius: 6px;
}
.instalmentoptions-content {
  padding-top: 10px;
}
@media (min-width: 768px) {
  .paymentoptionspage-header {
    font-size: 32px;
  }
  .payment-tablist > .tab-btn {
    font-size: 20px;
  }
  .mortgage-header {
    padding-top: 40px;
  }
  .mortgage-header > h1 {
    font-size: 24px;
    font-weight: 700;
  }
  .ratesbtn-wrapper {
    padding-bottom: 100px;
  }
  .mortgageoffering-companies {
    padding: 50px 0 100px 0;
  }
  .mortgagerate-card  {
    margin: 60px 0 30px 0;
  }
  .mortgage-companies_card {
    margin: 30px 0 30px 0;
  }
  .instalmentoptions-content {
    padding-top: 50px;
  }
}