.get-approval-page {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}
.get-approval-page .step-image {
  width: 100%;
  height: 100%;
  max-width: 680px;
  max-height: 550px;
  margin: auto;
  cursor: pointer;
}

.step-indicator {
  margin-top: 50px;
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.step-indicator .step-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #c2c2c2;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  position: relative;
  font-size: 20px;
}

.step.active .step-icon {
  border: 2px solid #cc5500;
  background: transparent;
}

.step.complete .step-icon {
  background: #cc5500;
  border: 2px solid #cc5500;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step p {
  text-align: center;
  position: absolute;
  bottom: -35px;
  color: #c2c2c2;
  font-size: 12px;
  font-weight: bold;
  text-wrap: nowrap;
}

.step.active p {
  color: #cc5500;
}

.step.step2 p,
.step.step3 p {
  left: 50%;
  transform: translateX(-50%);
}

.indicator-line {
  width: 100%;
  height: 2px;
  background: #c2c2c2;
  flex: 1;
}

.indicator-line.active {
  background: #cc5500;
}
.no-border:focus {
  outline: none !important;
  border: none !important;
}

.input-range__track--active {
  background: black !important;
}
.input-range__slider {
  background: black !important;
  border: 1px solid black !important;
}
.input-range__label {
  display: none;
}
.input-range-label {
  color: black;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.input-label {
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
}
.input-label-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.tip {
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  cursor: pointer;
  margin-top: 20px;
}

.employment-status {
  border: 1px solid black;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 16px;
  cursor: pointer;
}
.employment-status.active {
  background-color: #cc5500;
  color: white;
  border-color: #cc5500;
}
.response-container {
  margin: 3rem auto;
}
.response-container .response-header {
  font-size: 20px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
}
.response-container .response-message {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.back-button {
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}
.back-button:focus {
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.form-error {
  color: red;
  font-size: 16px;
  line-height: 29px;
}

@media screen and (max-width: 500px) {
  .step p {
    font-size: 11px;
    bottom: -40px;
    text-wrap: nowrap;
    white-space: nowrap;
  }
  .get-approval-page .logo {
    width: 100%;
    height: 100%;
    max-height: 100px;
    max-width: 100px;
    object-fit: contain;
  }
  .input-range {
    margin: 2px auto !important;
  }
}
