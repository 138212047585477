.help-card {
  text-align: center;
  transition: transform 0.3s;
  cursor: pointer;
  padding: 10px;
  background: #f3e9e9;
  border-radius: 15px;
  text-decoration: none;
}
.contact {
  display: none;
  font-weight: 500;
  color: #000000;
}

.help-card:hover {
  transform: translateY(-10px);
}
.help-card:hover .contact {
  display: block;
}
.help-card-help:hover .description {
  display: none;
}

.help-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.help-card .title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 15px;
}

.help-card .description {
  font-size: 14px;
  color: #212529;
  line-height: 20px;
}
